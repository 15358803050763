import MyCarousel from "./MyCarousel";
import Button from "react-bootstrap/Button";
import './main.scss';
import { Phone, TabletLandscape, Laptop, PcDisplayHorizontal, SuitDiamondFill, Youtube } from 'react-bootstrap-icons';
import word from './docs/John Beagan Resume.docx';
import pdf from './docs/John Beagan Resume.pdf';

const Main = () => {

    const tags: string[] = [
        'React',
        'Redux&nbsp;Toolkit',
        'TypeScript',
        'Bootstrap',
        'AWS&nbsp;CloudFront',
        'Azure&nbsp;Functions',
        'C#',
        'SQL&nbsp;Server',
        'MySQL'];

    const SpanList = () => {
        return (
            <div className="wrap-spans d-flex align-items-center">
                {
                    tags.map((s, k) => {
                        let tspan = "tag-span";
                        tspan = k === 0 ? "tag-span-first" : tspan;
                        tspan = k === (tags.length - 1) ? "tag-span-last" : tspan;
                        return <><span key={k} className={"tag " + tspan} dangerouslySetInnerHTML={{ __html: s }} />
                            {
                                k !== (tags.length - 1)
                                    ? <SuitDiamondFill style={{ fontSize: "0.5rem" }} />
                                    : null
                            }
                        </>
                    })
                }
            </div>
        )
    }


    const DivList = () => {
        return (
            <div className="d-flex align-items-end flex-column" style={{ marginRight: "1rem" }} >
                {
                    tags.map((s, k) => {
                        return <div key={k} className="tag tag-div" dangerouslySetInnerHTML={{ __html: s }} />
                    })
                }
            </div>
        )
    }

    return (
        <main >
            <MyCarousel />
            <header>
                <h2 >
                    John H. Beagan, Jr.
                </h2>
                <h1>
                    Web Development &#8212; Full Stack
                </h1>
            </header>
            <div className="icons">
                <Phone className="icon i-right" /> <TabletLandscape className="icon i-right" />
                <Laptop className="icon i-right" /> <PcDisplayHorizontal className="icon" />
            </div>
            <div className="text-content">
                <div className="row content-row">

                    <div className="d-none d-md-block col-md-1 d-lg-none" />

                    <div className="d-xs-block d-md-none col-xs-4 " style={{ marginBottom: "1rem" }}>
                        <SpanList />
                    </div>

                    <div className="d-none d-md-block col-md-2 col-lg-2" >
                        <DivList />
                    </div>

                    <div className="col-xs-8 col-md-8 col-lg-10" >
                        <p style={{ textIndent: "1rem" }}>
                            One Web application responding well across various devices, browsers and operating systems &#8212; a
                            compelling approach given today's cloud resources
                            and ease of cross-browser development using popular frameworks and libraries.
                        </p>
                        <p style={{ textIndent: "1rem" }}>
                            Design and develop for medium and small businesses, non-profits, and 
                            departments in larger organizations. Serving the Greater Boston area.
                        </p>
                        <p >
                            Principal developer of:
                            <ul className="my-list">
                                <li><a href="https://rsvxccc.com"
                                        target="_blank" rel="noopener noreferrer"
                                    >RSV x CCC</a> &#8212; a 
                                    proof of concept site for a much larger application that was spun-off. Uses React, Redux Toolkit,
                                    TypeScript, Azure Functions, and SQL Server. Scraped RSV Bible verses and Catholic Catechism 
                                    paragraphs off the Web
                                    to create a searchable resource with clickable cross-references. See
                                    YouTube short introduction <a href="https://youtu.be/2huAPCSXpWk"
                                        target="_blank" rel="noopener noreferrer"
                                    ><Youtube title="RSV x CCC" style={{ color: "red", fontSize: "1.4rem" }} /></a>
                                </li>
                                <li><a href="https://letspickadate.com"
                                        target="_blank" rel="noopener noreferrer"
                                    >Let's Pick A Date</a>, a site created using React/Redux and designed to help friends
                                    schedule a get-together by agreeing on a date and time. See 
                                    YouTube short introduction <a href="https://www.youtube.com/watch?v=k-y4K7WWGwY"
                                        target="_blank" rel="noopener noreferrer"
                                    ><Youtube title="Let's Pick A Date" style={{ color: "red", fontSize: "1.4rem" }} /></a>
                                </li>

                            </ul>
                        </p>
                        <p >
                            Technology highlights:
                            <ul className="my-list">
                                <li>Programming: React, Redux Toolkit, TypeScript, JavaScript, Bootstrap, C#.</li>
                                <li>Database: SQL Server &#8212; T-SQL, Reporting Services, Integration Services, Analysis Services.</li>
                                <li>Clouds: AWS and Azure.</li>
                                <li>Development Environment: VS Code, Visual Studio, GitHub, Jenkins, CircleCI.</li>
                                <li>Past Tools: ASP.NET MVC/Razor, VB.NET, Microsoft Visual FoxPro.</li>

                            </ul>
                        </p>

                        <p >Please see resume for contact information, professional background, and list of
                            significant projects:</p>

                        <p style={{marginLeft: "1rem"}}>
                            <Button variant="primary" href={pdf} target="_blank" rel="noopener noreferrer" style={{width: "4rem", marginRight: "1rem"}}>pdf</Button>
                            <Button variant="primary" href={word} target="_blank" rel="noopener noreferrer" style={{width: "4rem"}}>docx</Button>
                        </p>

                        <p >Served some clients continuously over 15 years.</p>
                    </div>

                    <div className="d-none d-md-block col-md-1 d-lg-none" />


                </div>
            </div>
        </main>
    );
}

export default Main;

/*
                    <div className="d-none d-md-block col-xl-1" />
                    <div className="d-none d-md-block col-xl-1" />

*/

