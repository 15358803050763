import './app.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar, Container } from 'react-bootstrap';
import Main from './Main';
import Catholic from './Catholic';

function App() {

  let title = "";
  title = window.location.pathname === "/" ? "JOHN BEAGAN" : "Encouraging the Faith";

  return (
    <>
      <Container className="main-container">
        <Navbar collapseOnSelect fixed='top' expand='sm' bg='light' variant='light' >
          <Navbar.Brand style={{ marginLeft: "2rem" }} href="/">{title}</Navbar.Brand>
        </Navbar>
        <BrowserRouter>
          <Routes>
            <Route path="/catholic" element={<Catholic />} />
            <Route path="/" element={<Main />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </>
  );
}

export default App;
