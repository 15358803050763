import { Image } from 'react-bootstrap'
import rosie from './images/RosieBaptism.jpg';
import './main.scss';

const Catholic = () => {


    return (
        <main >
            <Image src={rosie} className="img-fluid" title="Rosie's Baptism" />
            <header>
                <div className="catholic-h2" >
                    A Personal Case for Catholicism
                </div>
                <div className="catholic-h1" >
                    We Can Believe &#8212; 2 Books!
                </div>
            </header>
            <div className="text-content">
                <div className="row content-row-catholic">


                    <div className="col-12" >
                        <p style={{ textIndent: "1rem" }}>
                            Most Catholics are affected by various doubts and disbelief today. But we don't have to be.
                            The purpose of these books is to cut through the confusion, so we may see how easy it is to
                            believe in God, Jesus Christ and his Church, even if we currently have great doubt or little interest.
                        </p>

                        <p style={{ textIndent: "1rem" }}>
                            As generations of Catholics drift away from the faith,
                            I am deeply troubled by the thought of these people one day standing
                            before God, the father of Jesus crucified, after neglecting and forgetting
                            His only beloved son. Thus, these books contain a case, based on
                            evidence and reason, so we might confidently believe.
                        </p>

                        <p style={{ textIndent: "1rem" }}>
                            Each book website has links to free manuscripts and YouTube narrations
                            for synopsis and book, a well as links to Amazon paperback and Kindle.
                        </p>

                        <p>
                            Peace, JB
                        </p>
                    </div>

                    <div className="catholic-h3" style={{ color: "#ca8e4c" }} >
                        It Makes Sense to Believe
                    </div>
                    <div style={{ textIndent: "1rem" }}>
                        For those who doubt and disbelieve... or are lukewarm and disinterested...
                    </div>
                    <div className="book-button-outer" >
                        <div className="book-links">
                            <a href="https://www.ItMakesSenseToBelieve.com"
                                target="_blank" rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}>
                                <span
                                    className="book-button"
                                    style={{ backgroundColor: "#ca8e4c" }}
                                >
                                    Goto&nbsp;Book&nbsp;Website
                                </span>
                            </a>
                        </div>
                    </div>

                    <div className="catholic-h3" style={{ color: "#d12233" }} >
                        Passing the Faith onto Loved Ones
                    </div>
                    <div style={{ textIndent: "1rem" }}>
                        For those who love the Church...
                    </div>
                    <div className="book-button-outer" >
                        <div className="book-links">
                            <a href="https://www.PassingTheFaithOntoLovedOnes.com"
                                target="_blank" rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}>
                                <span
                                    className="book-button"
                                    style={{ backgroundColor: "#d12233" }}
                                >
                                    Goto&nbsp;Book&nbsp;Website
                                </span>
                            </a>
                        </div>
                    </div>

                    <div className="catholic-h3" style={{ color: "#a5b33a" }} >
                        Contact Information
                    </div>
                    <div style={{ textIndent: "1rem", paddingBottom: "12px" }}>
                        Please contact <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a> if you have any questions or feedback.
                        God bless!
                    </div>

                    <div className="d-flex flex-column">
                        <div style={{ textIndent: "1rem", paddingBottom: "6px", fontStyle: "italic" }}>Deacon John Beagan</div>
                        <div style={{ textIndent: "1rem" }}><a href="https://www.IntentionallyCatholic.com"
                            target="_blank" rel="noopener noreferrer"
                            style={{ textDecoration: "none", fontStyle: "italic" }}>
                            IntentionallyCatholic.com
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Catholic;

